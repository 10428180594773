.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header {
  width: 100%;
  height: 5vh;
  display: flex;
  padding: 3vh;
  align-items: center;
  background-color: var(--dl-color-primary-100);
  justify-content: center;
}
.home-text {
  color: var(--dl-color-gray-white);
  /* width: 100%; */
  margin: 0px;
  font-size: 2vh;
  align-self: center;
  text-align: center;
  font-family: Inter;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-center-frame {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image {
  width: 700px;
  padding: var(--dl-space-space-twounits);
  object-fit: cover;
}
.home-text01 {
  color: var(--dl-color-primary-500);
  padding: 0px;
  font-size: 3vh;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
}
.home-navlink {
  color: var(--dl-color-gray-white);
  margin: var(--dl-space-space-unit);
  padding: var(--dl-space-space-unit);
  transition: 0.3s;
  border-radius: 64px;
  padding-right: 16px;
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.home-navlink:hover {
  background-color: var(--dl-color-primary-700);
}
.home-text02 {
  font-size: 24px;
  font-style: normal;
  font-family: Inter;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text05 {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text07 {
  color: var(--dl-color-primary-300);
}
.home-text09 {
  margin-top: var(--dl-space-space-unit);
}
#logo{
  margin-right: 10px;
  height:4vh ;
}
@media(max-width: 1600px) {
  .home-text {
    font-size: 2vh;
  }
  .home-text01 {
    font-size: 3vh;
  }
  .home-text02 {
    font-size: 18px;
  }
  .home-footer {
    margin-top: var(--dl-space-space-twounits);
  }
  .trademark {
    font-size: 2vh;
    }
    #logo{
      margin-right: 10px;
      height:4vh ;
    }
}
@media(max-width: 1200px) {
  .home-text {
    font-size: 2vh;
  }
  .home-text01 {
    font-size: 3vh;
  }
  .home-text05 {
    text-align: center;
  }
  #logo{
    margin-right: 10px;
    height:4vh ;
  }
}
@media(max-width: 991px) {
  .home-text {
    font-size: 2vh;
  }
  .home-text01 {
    font-size: 3vh;
  }
  .home-text02 {
    font-size: 20px;
  }
  .home-text05 {
    text-align: center;
  }
  .home-text09 {
    text-align: center;
  }
  #logo{
    margin-right: 10px;
    height:4vh ;
  }
}
@media(max-width: 767px) {
  .home-text {
    font-size: 2vh;
  }
  .home-image {
    width: 700px;
  }
  .home-text01 {
    font-size: 2vh;
  }
  .home-text02 {
    font-size: 15px;
  }
  #logo{
    margin-right: 10px;
    height:3vh ;
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: 2vh;
  }
  .home-text {
    font-size: 2vh;
  }
  .home-image {
    width: 400px;
  }
  .home-text01 {
    font-size: 2vh;
  }
  .trademark {
    font-size: 1vh;
    }
    #logo{
      margin-right: 10px;
      height:3vh ;
    }
}
