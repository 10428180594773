body {
    background-color: #f2f2f2;
    font-family: Arial, sans-serif;
  }
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  input[type='text'],
  input[type='password'] {
    display: block;
    width: 30%;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  .button_download {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button_download:hover {
    background-color: #3e8e41;
  }