.pageslider-intro-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
  flex-direction: column;
  padding-bottom: 5vh;
  justify-content: flex-start;
}
.pageslider-intro-p1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-intro-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-intro-video {
  margin-top: 1vh;
  width: 30%;
  height: auto;
}
.pageslider-intro-summarybutton {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-intro-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-intro-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-intro-text {
  color: #ff631e;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-intro-ul {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-intro-li {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-text01 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li01 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text02 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-container_nav {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-replay {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-pause {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-play {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next:hover {
  fill: #777070;
}
.pageslider-intro-next.disabled {
  fill: grey;
  cursor: not-allowed;
}
.pageslider-intro-p2 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-intro-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-intro-video1 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-intro-summarybutton1 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-intro-summarybutton1:hover {
  background-color: #e2e2e2;
}
.pageslider-intro-summary1 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-intro-text03 {
  color: #ff631e;
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-intro-ul1 {
  width: auto;
  height: auto;
}
.pageslider-intro-li02 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text04 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li03 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text05 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li04 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text06 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li05 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text07 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-next1 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next1:hover {
  fill: #777070;
}
.pageslider-intro-p3 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-intro-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-intro-video2 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-intro-summarybutton2 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-intro-summarybutton2:hover {
  background-color: #e2e2e2;
}
.pageslider-intro-summary2 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-intro-text08 {
  color: #ff631e;
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-intro-ul2 {
  width: auto;
  height: auto;
}
.pageslider-intro-li06 {
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container10 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-checkbox {
  width: 2vh;
  height: 2vh;
  align-self: center;
}
.pageslider-intro-container12 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-text09 {
  width: 100%;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li07 {
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-checkbox1 {
  width: 2vh;
  height: 2vh;
  align-self: center;
}
.pageslider-intro-container15 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-text10 {
  width: 100%;
  height: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li08 {
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-checkbox2 {
  width: 2vh;
  height: 2vh;
  align-self: center;
}
.pageslider-intro-container18 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-text11 {
  width: 100%;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li09 {
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-container20 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-checkbox3 {
  width: 2vh;
  height: 2vh;
  align-self: center;
}
.pageslider-intro-container21 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-text12 {
  width: 100%;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li10 {
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container22 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-container23 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-checkbox4 {
  width: 2vh;
  height: 2vh;
  align-self: center;
}
.pageslider-intro-container24 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-text13 {
  width: 100%;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li11 {
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container25 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-container26 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-checkbox5 {
  width: 2vh;
  height: 2vh;
  align-self: center;
}
.pageslider-intro-container27 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-text14 {
  width: 100%;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-next2 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next2:hover {
  fill: #777070;
}
.pageslider-intro-p4 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-intro-container28 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-intro-video3 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-intro-summarybutton3 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-intro-summarybutton3:hover {
  background-color: #e2e2e2;
}
.pageslider-intro-summary3 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-intro-text15 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-intro-ul3 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-intro-li12 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-intro-text16 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li13 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text17 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li14 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text18 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-li15 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-intro-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-intro-text19 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-intro-text20 {
  color: #ff631e;
  font-style: normal;
  font-weight: 600;
}
.pageslider-intro-next3 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next3:hover {
  fill: #777070;
}
.pageslider-intro-p5 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-intro-container33 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-intro-video4 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-intro-summarybutton4 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-intro-summarybutton4:hover {
  background-color: #e2e2e2;
}
.pageslider-intro-summary4 {
  width: 70%;
  height: auto;
  margin: 1vh;
  margin-bottom: 0;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-intro-text22 {
  color: rgb(255, 99, 30);
  width: 100%;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-intro-text23 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 2.3vh;
  margin-top: var(--dl-space-space-halfunit);
}
.pageslider-intro-ul4 {
  list-style-type: circle;
  margin-bottom: 0;
  margin-top: 0.5vh;
}
.pageslider-intro-li16 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.pageslider-intro-text28 {
  font-size: 2.3vh;
}
.pageslider-intro-li17 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.pageslider-intro-text29 {
  font-size: 2.3vh;
}
.pageslider-intro-li18 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.pageslider-intro-text30 {
  font-size: 2.3vh;
}
.pageslider-intro-li19 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.pageslider-intro-text31 {
  font-size: 2.3vh;
}
.pageslider-intro-li20 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.pageslider-intro-text32 {
  font-size: 2.3vh;
}
.pageslider-intro-li21 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.pageslider-intro-text33 {
  font-size: 2.3vh;
}
.pageslider-intro-navlink {
  display: contents;
}
.pageslider-intro-next4 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next4:hover {
  fill: #777070;
}


.pageslider-intro-root-class-name2 {
  height: 100%;
  align-self: center;
}
.pageslider-intro-root-class-name3 {
  height: 100%;
  align-self: center;
}

@media(max-width: 1600px) {
  .pageslider-intro-video {
    width: 30%;
  }
  .pageslider-intro-video1 {
    width: 35%;
  }
  .pageslider-intro-text03 {
    color: rgb(255, 99, 30);
  }
  .pageslider-intro-video2 {
    width: 35%;
  }
  .pageslider-intro-text08 {
    color: rgb(255, 99, 30);
  }
  .pageslider-intro-video3 {
    width: 35%;
  }
  .pageslider-intro-text15 {
    color: rgb(255, 99, 30);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-intro-video4 {
    width: 35%;
  }
}
@media(max-width: 1200px) {
  .pageslider-intro-summary {
    width: 80%;
  }
  .pageslider-intro-li {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-container02 {
    justify-content: flex-start;
  }
  .pageslider-intro-li01 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-container03 {
    justify-content: flex-start;
  }
  .pageslider-intro-summary1 {
    width: 80%;
  }
  .pageslider-intro-text03 {
    color: rgb(255, 99, 30);
  }
  .pageslider-intro-li02 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-intro-container05 {
    justify-content: flex-start;
  }
  .pageslider-intro-li03 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-intro-container06 {
    justify-content: flex-start;
  }
  .pageslider-intro-li04 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-intro-container07 {
    justify-content: flex-start;
  }
  .pageslider-intro-li05 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-intro-container08 {
    justify-content: flex-start;
  }
  .pageslider-intro-summary2 {
    width: 80%;
  }
  .pageslider-intro-text08 {
    color: rgb(255, 99, 30);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-intro-li06 {
    text-align: left;
  }
  .pageslider-intro-text09 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li07 {
    text-align: left;
  }
  .pageslider-intro-text10 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li08 {
    text-align: left;
  }
  .pageslider-intro-text11 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li09 {
    text-align: left;
  }
  .pageslider-intro-text12 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li10 {
    text-align: left;
  }
  .pageslider-intro-text13 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li11 {
    text-align: left;
  }
  .pageslider-intro-text14 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-summary3 {
    width: 80%;
  }
  .pageslider-intro-li12 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-container29 {
    justify-content: flex-start;
  }
  .pageslider-intro-li13 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-container30 {
    justify-content: flex-start;
  }
  .pageslider-intro-li14 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-container31 {
    justify-content: flex-start;
  }
  .pageslider-intro-li15 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-container32 {
    justify-content: flex-start;
  }
  .pageslider-intro-summary4 {
    width: 95%;
  }
}
@media(max-width: 991px) {
  .pageslider-intro-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-intro-container01 {
    flex-direction: column;
  }
  .pageslider-intro-video {
    width: 50%;
  }
  .pageslider-intro-p2 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-intro-container04 {
    flex-direction: column;
  }
  .pageslider-intro-video1 {
    width: 50%;
  }
  .pageslider-intro-p3 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-intro-container09 {
    flex-direction: column;
  }
  .pageslider-intro-video2 {
    width: 50%;
  }
  .pageslider-intro-p4 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-intro-container28 {
    flex-direction: column;
  }
  .pageslider-intro-video3 {
    width: 50%;
  }
  .pageslider-intro-p5 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-intro-container33 {
    flex-direction: column;
  }
  .pageslider-intro-video4 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .pageslider-intro-video {
    width: 55%;
  }
  .pageslider-intro-text {
    width: 100%;
  }
  .pageslider-intro-video1 {
    width: 70%;
  }
  .pageslider-intro-text03 {
    width: 100%;
  }
  .pageslider-intro-video2 {
    width: 55%;
  }
  .pageslider-intro-text08 {
    width: 100%;
  }
  .pageslider-intro-li06 {
    text-align: left;
  }
  .pageslider-intro-checkbox {
    margin-right: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text09 {
    text-align: left;
  }
  .pageslider-intro-checkbox1 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text10 {
    text-align: left;
  }
  .pageslider-intro-checkbox2 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text11 {
    text-align: left;
  }
  .pageslider-intro-checkbox3 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text12 {
    text-align: left;
  }
  .pageslider-intro-checkbox4 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text13 {
    text-align: left;
  }
  .pageslider-intro-checkbox5 {
    margin-right: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text14 {
    text-align: left;
  }
  .pageslider-intro-video3 {
    width: 55%;
  }
  .pageslider-intro-text15 {
    color: rgb(255, 99, 30);
    width: 100%;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-intro-text22 {
    color: rgb(255, 99, 30);
  }
  .pageslider-intro-text23 {
    color: var(--dl-color-gray-black);
  }
  .pageslider-intro-video4 {
    width: 55%;
  }
  .pageslider-intro-summary{
    width: 95%;
  }
  .pageslider-intro-summary2{
    width: 95%;
  }
  .pageslider-intro-summary3{
    width: 95%;
  }
  .pageslider-intro-summary4{
    width: 95%;
  }
}
@media(max-width: 479px) {
  .pageslider-intro-container {
    width: 361px;
    height: 100%;
  }
  .pageslider-intro-p1 {
    width: 100%;
  }
  .pageslider-intro-video {
    width: 100%;
  }
  .pageslider-intro-summary {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-intro-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-intro-li {
    font-size: 1.8vh;
  }
  .pageslider-intro-text01 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li01 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text02 {
    font-size: 1.8vh;
  }
  .pageslider-intro-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-p2 {
    width: 100%;
  }
  .pageslider-intro-video1 {
    width: 100%;
  }
  .pageslider-intro-summary1 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text03 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-intro-ul1 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: decimal;
    list-style-position: outside;
  }
  .pageslider-intro-li02 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text04 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li03 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text05 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li04 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text06 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li05 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text07 {
    font-size: 1.8vh;
  }
  .pageslider-intro-next1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-p3 {
    width: 100%;
  }
  .pageslider-intro-video2 {
    width: 100%;
  }
  .pageslider-intro-summary2 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text08 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-intro-ul2 {
    padding-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li06 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-checkbox {
    height: auto;
    margin-right: 0px;
  }
  .pageslider-intro-container12 {
    width: 100%;
  }
  .pageslider-intro-text09 {
    width: 100%;
    height: auto;
    font-size: 1.8vh;
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li07 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-checkbox1 {
    height: auto;
    margin-right: 0px;
  }
  .pageslider-intro-container15 {
    width: 100%;
  }
  .pageslider-intro-text10 {
    width: 100%;
    height: auto;
    font-size: 1.8vh;
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li08 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-container16 {
    justify-content: flex-start;
  }
  .pageslider-intro-container17 {
    width: auto;
    height: auto;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .pageslider-intro-checkbox2 {
    height: auto;
    margin-right: 0px;
  }
  .pageslider-intro-container18 {
    width: 100%;
    height: 48px;
    align-self: center;
    justify-content: flex-start;
  }
  .pageslider-intro-text11 {
    width: 100%;
    height: auto;
    font-size: 1.8vh;
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li09 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-checkbox3 {
    height: auto;
    margin-right: 0px;
  }
  .pageslider-intro-container21 {
    width: 100%;
  }
  .pageslider-intro-text12 {
    height: auto;
    font-size: 1.8vh;
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-li10 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-checkbox4 {
    height: auto;
    margin-right: 0px;
  }
  .pageslider-intro-container24 {
    width: 100%;
    height: 40px;
  }
  .pageslider-intro-text13 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li11 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-checkbox5 {
    height: auto;
    margin-right: 0px;
  }
  .pageslider-intro-container27 {
    width: 100%;
  }
  .pageslider-intro-text14 {
    height: auto;
    font-size: 1.8vh;
    margin-left: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-next2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-p4 {
    width: 100%;
  }
  .pageslider-intro-video3 {
    width: 100%;
  }
  .pageslider-intro-summary3 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text15 {
    color: rgb(255, 99, 30);
    font-size: 2vh;
  }
  .pageslider-intro-ul3 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-intro-li12 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text16 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li13 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text17 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li14 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text18 {
    font-size: 1.8vh;
  }
  .pageslider-intro-li15 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text19 {
    font-size: 1.8vh;
  }
  .pageslider-intro-next3 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-p5 {
    width: 100%;
  }
  .pageslider-intro-video4 {
    width: 100%;
  }
  .pageslider-intro-summary4 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-text22 {
    font-size: 2vh;
  }
  .pageslider-intro-text23 {
    color: var(--dl-color-gray-black);
    width: 100%;
    font-size: 1.8vh;
    text-align: justify;
  }
  .pageslider-intro-text25 {
    color: var(--dl-color-gray-black);
    font-size: 1.2rem;
  }
  .pageslider-intro-text28 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text29 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text30 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text31 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text32 {
    font-size: 1.8vh;
  }
  .pageslider-intro-text33 {
    font-size: 1.8vh;
  }
  .pageslider-intro-next4 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-intro-root-class-name2 {
    top: 100px;
    left: 0px;
    height: auto;
    position: static;
  }
  .pageslider-intro-root-class-name3 {
    top: 100px;
    left: 0px;
    height: auto;
    position: static;
  }
}
