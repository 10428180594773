.pageslider-pulse-point-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
  flex-direction: column;
  padding-bottom: 5vh;
  justify-content: flex-start;
}
.pageslider-pulse-point-p1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-pulse-point-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-pulse-point-video {
  width: 35%;
  height: auto;
  margin-top: 1vh;
}
.pageslider-pulse-point-summarybutton {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-pulse-point-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-pulse-point-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-pulse-point-text {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-pulse-point-ul {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-pulse-point-li {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-pulse-point-text01 {
  font-size: 2.3vh;
  text-align: center;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li01 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-pulse-point-text02 {
  font-size: 2.3vh;
  text-align: center;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-pulse-point-next:hover {
  fill: #777070;
}
.pageslider-pulse-point-p2 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-pulse-point-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-pulse-point-video1 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-pulse-point-summarybutton1 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-pulse-point-summarybutton1:hover {
  background-color: #e2e2e2;
}
.pageslider-pulse-point-summary1 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-pulse-point-text05 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-pulse-point-ul1 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-pulse-point-li02 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-pulse-point-text06 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li03 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text07 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li04 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text08 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li05 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text09 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-next1 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-pulse-point-next1:hover {
  fill: #777070;
}
.pageslider-pulse-point-p3 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-pulse-point-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-pulse-point-video2 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-pulse-point-summarybutton2 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-pulse-point-summarybutton2:hover {
  background-color: #e2e2e2;
}
.pageslider-pulse-point-summary2 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-pulse-point-text10 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-pulse-point-ul2 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-pulse-point-li06 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-pulse-point-text11 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li07 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text12 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li08 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text13 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li09 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text14 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-next2 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-pulse-point-next2:hover {
  fill: #777070;
}
.pageslider-pulse-point-p4 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-pulse-point-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-pulse-point-video3 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-pulse-point-summarybutton3 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-pulse-point-summarybutton3:hover {
  background-color: #e2e2e2;
}
.pageslider-pulse-point-summary3 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  margin-bottom: 0;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-pulse-point-text15 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-pulse-point-ul3 {
  width: auto;
  height: auto;
  list-style-type: circle;
  margin-bottom: 0;
}
.pageslider-pulse-point-li10 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-pulse-point-text16 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li11 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text17 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li12 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text18 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li13 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text19 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li14 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text20 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li15 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text21 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li16 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text22 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-next3 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-pulse-point-next3:hover {
  fill: #777070;
}
.pageslider-pulse-point-p5 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-pulse-point-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-pulse-point-video4 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-pulse-point-summarybutton4 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-pulse-point-summarybutton4:hover {
  background-color: #e2e2e2;
}
.pageslider-pulse-point-summary4 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-pulse-point-text23 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-pulse-point-ul4 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-pulse-point-li17 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-pulse-point-text24 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li18 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text25 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li19 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text26 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li20 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text27 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-next4 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-pulse-point-next4:hover {
  fill: #777070;
}
.pageslider-pulse-point-p6 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-pulse-point-container27 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-pulse-point-video5 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-pulse-point-summarybutton5 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-pulse-point-summarybutton5:hover {
  background-color: #e2e2e2;
}
.pageslider-pulse-point-summary5 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-pulse-point-text28 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-pulse-point-ul5 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-pulse-point-li21 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container28 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-pulse-point-text29 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li22 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text30 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-li23 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-pulse-point-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-pulse-point-text31 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-pulse-point-next5 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-pulse-point-next5:hover {
  fill: #777070;
}
.pageslider-pulse-point-p10 {
  width: 100%;
  height: auto;
  display: none;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-pulse-point-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-pulse-point-container33 {
  display: contents;
}
.pageslider-pulse-point-next6 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-pulse-point-next6:hover {
  fill: #777070;
}

@media(max-width: 1600px) {
  .pageslider-pulse-point-video {
    width: 35%;
  }
  .pageslider-pulse-point-text {
    color: rgb(255, 99, 30);
  }
  .pageslider-pulse-point-video1 {
    width: 35%;
  }
  .pageslider-pulse-point-video2 {
    width: 35%;
  }
  .pageslider-pulse-point-video3 {
    width: 35%;
  }
  .pageslider-pulse-point-video4 {
    width: 35%;
  }
  .pageslider-pulse-point-video5 {
    width: 35%;
  }
}
@media(max-width: 1200px) {
  .pageslider-pulse-point-summary {
    width: 80%;
  }
  .pageslider-pulse-point-text {
    color: rgb(255, 99, 30);
  }
  .pageslider-pulse-point-container02 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-container03 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-summary1 {
    width: 80%;
  }
  .pageslider-pulse-point-li02 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container05 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li03 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container06 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li04 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container07 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li05 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container08 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-summary2 {
    width: 80%;
  }
  .pageslider-pulse-point-li06 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container10 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li07 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container11 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li08 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container12 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li09 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container13 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-summary3 {
    width: 80%;
    margin-bottom: 0;
  }
  .pageslider-pulse-point-li10 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container15 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li11 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container16 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li12 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container17 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li13 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container18 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li14 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container19 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li15 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container20 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li16 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container21 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-summary4 {
    width: 80%;
  }
  .pageslider-pulse-point-li17 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container23 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li18 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container24 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li19 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container25 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li20 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container26 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-summary5 {
    width: 80%;
  }
  .pageslider-pulse-point-li21 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container28 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li22 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container29 {
    justify-content: flex-start;
  }
  .pageslider-pulse-point-li23 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-container30 {
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pageslider-pulse-point-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-pulse-point-container01 {
    flex-direction: column;
  }
  .pageslider-pulse-point-video {
    width: 50%;
  }
  .pageslider-pulse-point-p2 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-pulse-point-container04 {
    flex-direction: column;
  }
  .pageslider-pulse-point-video1 {
    width: 50%;
  }
  .pageslider-pulse-point-p3 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-pulse-point-container09 {
    flex-direction: column;
  }
  .pageslider-pulse-point-video2 {
    width: 50%;
  }
  .pageslider-pulse-point-p4 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-pulse-point-container14 {
    flex-direction: column;
  }
  .pageslider-pulse-point-video3 {
    width: 50%;
  }
  .pageslider-pulse-point-p5 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-pulse-point-container22 {
    flex-direction: column;
  }
  .pageslider-pulse-point-video4 {
    width: 50%;
  }
  .pageslider-pulse-point-p6 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-pulse-point-container27 {
    flex-direction: column;
  }
  .pageslider-pulse-point-video5 {
    width: 50%;
  }
  .pageslider-pulse-point-p10 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-pulse-point-container31 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .pageslider-pulse-point-video {
    width: 70%;
  }
  .pageslider-pulse-point-text {
    width: 100%;
  }
  .pageslider-pulse-point-video1 {
    width: 70%;
  }
  .pageslider-pulse-point-text05 {
    width: 100%;
  }
  .pageslider-pulse-point-video2 {
    width: 70%;
  }
  .pageslider-pulse-point-text10 {
    width: 100%;
  }
  .pageslider-pulse-point-video3 {
    width: 70%;
  }
  .pageslider-pulse-point-text15 {
    width: 100%;
  }
  .pageslider-pulse-point-video4 {
    width: 70%;
  }
  .pageslider-pulse-point-text23 {
    width: 100%;
  }
  .pageslider-pulse-point-video5 {
    width: 70%;
  }
  .pageslider-pulse-point-text28 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pageslider-pulse-point-container {
    width: 100%;
    height: 100%;
  }
  .pageslider-pulse-point-p1 {
    width: 100%;
  }
  .pageslider-pulse-point-video {
    width: 100%;
  }
  .pageslider-pulse-point-summary {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-pulse-point-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-pulse-point-li {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text01 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li01 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text02 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text03 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-p2 {
    width: 100%;
  }
  .pageslider-pulse-point-video1 {
    width: 100%;
  }
  .pageslider-pulse-point-summary1 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-text05 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-pulse-point-ul1 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-pulse-point-li02 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text06 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li03 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text07 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li04 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text08 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li05 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text09 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-next1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-p3 {
    width: 100%;
  }
  .pageslider-pulse-point-video2 {
    width: 100%;
  }
  .pageslider-pulse-point-summary2 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-text10 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-pulse-point-ul2 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-pulse-point-li06 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text11 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li07 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text12 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li08 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text13 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li09 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text14 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-next2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-p4 {
    width: 100%;
  }
  .pageslider-pulse-point-video3 {
    width: 100%;
  }
  .pageslider-pulse-point-summary3 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
    margin-bottom: 0;
  }
  .pageslider-pulse-point-text15 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-pulse-point-ul3 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-pulse-point-li10 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text16 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li11 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text17 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li12 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text18 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li13 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text19 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li14 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text20 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li15 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text21 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li16 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text22 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-next3 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-p5 {
    width: 100%;
  }
  .pageslider-pulse-point-video4 {
    width: 100%;
  }
  .pageslider-pulse-point-summary4 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-text23 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-pulse-point-ul4 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-pulse-point-li17 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text24 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li18 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text25 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li19 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text26 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li20 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text27 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-next4 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-p6 {
    width: 100%;
  }
  .pageslider-pulse-point-video5 {
    width: 100%;
  }
  .pageslider-pulse-point-summary5 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-text28 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-pulse-point-ul5 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-pulse-point-li21 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text29 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li22 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text30 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-li23 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-text31 {
    font-size: 1.8vh;
  }
  .pageslider-pulse-point-next5 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-pulse-point-p10 {
    width: 100%;
  }
  .pageslider-pulse-point-next6 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
.pageslider-intro-container_nav {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-replay {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-pause {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-play {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next:hover {
  fill: #777070;
}