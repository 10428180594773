.how-narcan-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}
.how-narcan-header {
  width: 100%;
  height: 5vh;
  display: flex;
  padding: 3vh;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.how-narcan-heading {
  color: var(--dl-color-gray-white);
  width: 100%;
  /* padding: 1vh; */
  margin: 0;
  font-size: 2vh;
  align-self: center;
  text-align: center;
  font-family: Inter;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.how-narcan-text1 {
  font-size: 0.5vh;
}
.how-narcan-footer {
  width: 100%;
  bottom: 0;
  height: 5vh;
  display: flex;
  position: absolute;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.how-narcan-text2 {
  font-size: 1vh;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.how-narcan-text4 {
  color: var(--dl-color-primary-300);
}
.how-narcan-text6 {
  font-size: 1vh;
  margin-top: 1vh;
}
@media(max-width: 1600px) {
  .how-narcan-heading {
    color: var(--dl-color-gray-white);
    font-size: 2vh;
    font-family: Inter;
    font-weight: 800;
    text-transform: none;
    text-decoration: none;
  }
  .how-narcan-text1 {
    font-size: 1vh;
  }
}
@media(max-width: 1200px) {
  .how-narcan-heading {
    font-size: 2vh;
  }
  .how-narcan-text2 {
    text-align: center;
  }
}
@media(max-width: 991px) {
  .how-narcan-heading {
    font-size: 2vh;
  }
  .how-narcan-text2 {
    text-align: center;
  }
  .how-narcan-text6 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .how-narcan-heading {
    font-size: 2vh;
  }
}
@media(max-width: 479px) {
  .how-narcan-heading {
    font-size: 2vh;
  }
  .how-narcan-footer {
    left: 0px;
    right: 0px;
    width: auto;
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .how-narcan-text6 {
    margin-top: 1vh;
  }
}
