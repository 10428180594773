.pageslider-stigma-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
  flex-direction: column;
  padding-bottom: 5vh;
  justify-content: flex-start;
}
.pageslider-stigma-p1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-stigma-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-stigma-video {
  width: 30%;
  height: auto;
  margin-top: 1vh;
}
.pageslider-stigma-summarybutton {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-stigma-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-stigma-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-stigma-text {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-stigma-ul {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-stigma-li {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-stigma-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-stigma-text1 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-stigma-li1 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-stigma-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-stigma-text2 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-stigma-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-stigma-next:hover {
  fill: #777070;
}
@media(max-width: 1600px) {
  .pageslider-stigma-video {
    width: 35%;
  }
  .pageslider-stigma-text {
    color: rgb(255, 99, 30);
  }
}
@media(max-width: 1200px) {
  .pageslider-stigma-summary {
    width: 80%;
  }
  .pageslider-stigma-text {
    color: rgb(255, 99, 30);
  }
  .pageslider-stigma-container2 {
    justify-content: flex-start;
  }
  .pageslider-stigma-container3 {
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pageslider-stigma-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-stigma-container1 {
    flex-direction: column;
  }
  .pageslider-stigma-video {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .pageslider-stigma-video {
    width: 70%;
  }
  .pageslider-stigma-text {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pageslider-stigma-container {
    width: 100%;
    height: 100%;
  }
  .pageslider-stigma-p1 {
    width: 100%;
  }
  .pageslider-stigma-video {
    width: 100%;
  }
  .pageslider-stigma-summary {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-stigma-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-stigma-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-stigma-li {
    font-size: 1.8vh;
  }
  .pageslider-stigma-text1 {
    font-size: 1.8vh;
  }
  .pageslider-stigma-li1 {
    font-size: 1.8vh;
  }
  .pageslider-stigma-text2 {
    font-size: 1.8vh;
  }
  .pageslider-stigma-text3 {
    font-size: 1.8vh;
  }
  .pageslider-stigma-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
.pageslider-intro-container_nav {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-replay {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-pause {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-play {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next:hover {
  fill: #777070;
}