.test-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.test-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.test-container2 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.test-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-xxlarge);
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ff631e;
}
.test-text {
  color: rgb(255, 255, 255);
  width: 95%;
  height: auto;
  align-self: center;
  text-align: center;
}
@media(max-width: 1600px) {
  .test-text {
    width: 95%;
    height: auto;
  }
}
@media(max-width: 1200px) {
  .test-text {
    width: 95%;
    height: auto;
  }
}
@media(max-width: 991px) {
  .test-text {
    width: 95%;
    height: auto;
    margin-top: 0px;
  }
}
@media(max-width: 767px) {
  .test-text {
    color: rgb(255, 255, 255);
    width: 95%;
    height: auto;
  }
}
@media(max-width: 479px) {
  .test-text {
    width: 95%;
    height: auto;
  }
}
