.pageslider-signs-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
  flex-direction: column;
  padding-bottom: 5vh;
  justify-content: flex-start;
}
.pageslider-signs-p1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-signs-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-signs-video {
  width: 30%;
  height: auto;
  margin-top: 1vh;
}
.pageslider-signs-summarybutton {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-signs-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-signs-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-signs-text {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-signs-ul {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-signs-li {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-signs-text01 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li01 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text02 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-signs-next:hover {
  fill: #777070;
}
.pageslider-signs-p2 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-signs-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-signs-video1 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-signs-summarybutton1 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-signs-summarybutton1:hover {
  background-color: #e2e2e2;
}
.pageslider-signs-summary1 {
  width: 60%;
  height: auto;
  margin: 1vh;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-signs-text03 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-signs-ul1 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-signs-li02 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text04 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li03 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text05 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li04 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text06 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li05 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text07 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-next1 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-signs-next1:hover {
  fill: #777070;
}
.pageslider-signs-p3 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-signs-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-signs-video2 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-signs-summarybutton2 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-signs-summarybutton2:hover {
  background-color: #e2e2e2;
}
.pageslider-signs-summary2 {
  width: 60%;
  height: auto;
  margin: 1vh;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-signs-text08 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-signs-ul2 {
  width: auto;
  height: auto;
}
.pageslider-signs-li06 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text09 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.check-mark {
  color: black;
}

.pageslider-signs-li07 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text10 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li08 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text11 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li09 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-signs-text12 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-next2 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-signs-next2:hover {
  fill: #777070;
}
.pageslider-signs-p4 {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-signs-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-signs-video3 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-signs-summarybutton3 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-signs-summarybutton3:hover {
  background-color: #e2e2e2;
}
.pageslider-signs-summary3 {
  width: 60%;
  height: auto;
  margin: 1vh;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-signs-text13 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-signs-ul3 {
  width: 50%;
  height: auto;
  padding-left: 0px;
  list-style-position: inside;
}
.pageslider-signs-li10 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text14 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li11 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text15 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li12 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text16 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li13 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text17 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-next3 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-signs-next3:hover {
  fill: #777070;
}
.pageslider-signs-p5 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-signs-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-signs-video4 {
  width: 30%;
  height: auto;
  margin-top: 1vh;
}
.pageslider-signs-summarybutton4 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-signs-summarybutton4:hover {
  background-color: #e2e2e2;
}
.pageslider-signs-summary4 {
  width: 60%;
  height: auto;
  margin: 1vh;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-signs-text18 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-signs-ul4 {
  width: 50%;
  height: auto;
  list-style-position: inside;
}
.pageslider-signs-li14 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text19 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li15 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text20 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li16 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text21 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li17 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text22 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-li18 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-signs-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-signs-text23 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-signs-next4 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-signs-next4:hover {
  fill: #777070;
}

@media(max-width: 1600px) {
  .pageslider-signs-video {
    width: 35%;
  }
  .pageslider-signs-video1 {
    width: 40%;
  }
  .pageslider-signs-text03 {
    color: rgb(255, 99, 30);
  }
  .pageslider-signs-video2 {
    width: 40%;
  }
  .pageslider-signs-text08 {
    color: rgb(255, 99, 30);
  }
  .pageslider-signs-video3 {
    width: 40%;
  }
  .pageslider-signs-text13 {
    color: rgb(255, 99, 30);
  }
  .pageslider-signs-video4 {
    width: 35%;
  }
  .pageslider-signs-text18 {
    color: rgb(255, 99, 30);
  }
}
@media(max-width: 1200px) {
  .pageslider-signs-summary {
    width: 80%;
  }
  .pageslider-signs-li {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-signs-container02 {
    justify-content: flex-start;
  }
  .pageslider-signs-li01 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-signs-container03 {
    justify-content: flex-start;
  }
  .pageslider-signs-summary1 {
    width: 90%;
  }
  .pageslider-signs-text03 {
    color: rgb(255, 99, 30);
  }
  .pageslider-signs-container05 {
    justify-content: flex-start;
  }
  .pageslider-signs-container06 {
    justify-content: flex-start;
  }
  .pageslider-signs-container07 {
    justify-content: flex-start;
  }
  .pageslider-signs-container08 {
    justify-content: flex-start;
  }
  .pageslider-signs-summary2 {
    width: 90%;
  }
  .pageslider-signs-text08 {
    color: rgb(255, 99, 30);
  }
  .pageslider-signs-li06 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-signs-container10 {
    justify-content: flex-start;
  }
  .pageslider-signs-li07 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-signs-container11 {
    justify-content: flex-start;
  }
  .pageslider-signs-li08 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-signs-container12 {
    justify-content: flex-start;
  }
  .pageslider-signs-li09 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-signs-container13 {
    justify-content: flex-start;
  }
  .pageslider-signs-summary3 {
    width: 90%;
  }
  .pageslider-signs-text13 {
    color: rgb(255, 99, 30);
  }
  .pageslider-signs-summary4 {
    width: 90%;
  }
  .pageslider-signs-text18 {
    color: rgb(255, 99, 30);
  }
}
@media(max-width: 991px) {
  .pageslider-signs-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-signs-container01 {
    flex-direction: column;
  }
  .pageslider-signs-video {
    width: 50%;
  }
  .pageslider-signs-p2 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-signs-container04 {
    flex-direction: column;
  }
  .pageslider-signs-video1 {
    width: 50%;
  }
  .pageslider-signs-p3 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-signs-container09 {
    flex-direction: column;
  }
  .pageslider-signs-video2 {
    width: 50%;
  }
  .pageslider-signs-p4 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-signs-container14 {
    flex-direction: column;
  }
  .pageslider-signs-video3 {
    width: 50%;
  }
  .pageslider-signs-p5 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-signs-container19 {
    flex-direction: column;
  }
  .pageslider-signs-video4 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .pageslider-signs-video {
    width: 70%;
  }
  .pageslider-signs-text {
    width: 100%;
  }
  .pageslider-signs-video1 {
    width: 70%;
  }
  .pageslider-signs-text03 {
    width: 100%;
  }
  .pageslider-signs-video2 {
    width: 70%;
  }
  .pageslider-signs-text08 {
    width: 100%;
  }
  .pageslider-signs-video3 {
    width: 70%;
  }
  .pageslider-signs-text13 {
    width: 100%;
  }
  .pageslider-signs-video4 {
    width: 70%;
  }
  .pageslider-signs-text18 {
    width: 100%;
  }
  .pageslider-signs-ul4 {
    width: 100%;
    padding-left: 0px;
  }
}
@media(max-width: 479px) {
  .pageslider-signs-container {
    width: 100%;
    height: 100%;
  }
  .pageslider-signs-p1 {
    width: 100%;
  }
  .pageslider-signs-video {
    width: 100%;
  }
  .pageslider-signs-summary {
    width: auto;
    margin: 1vh;
  }
  .pageslider-signs-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-signs-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-signs-li {
    font-size: 1.8vh;
  }
  .pageslider-signs-text01 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li01 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text02 {
    font-size: 1.8vh;
  }
  .pageslider-signs-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-signs-p2 {
    width: 100%;
  }
  .pageslider-signs-video1 {
    width: 100%;
  }
  .pageslider-signs-summary1 {
    width: auto;
    margin: 1vh;
  }
  .pageslider-signs-text03 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-signs-ul1 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-signs-li02 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text04 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li03 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text05 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li04 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text06 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li05 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text07 {
    font-size: 1.8vh;
  }
  .pageslider-signs-next1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-signs-p3 {
    width: 100%;
  }
  .pageslider-signs-video2 {
    width: 100%;
  }
  .pageslider-signs-summary2 {
    width: auto;
    margin: 1vh;
  }
  .pageslider-signs-text08 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-signs-ul2 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: decimal;
    list-style-position: outside;
  }
  .pageslider-signs-li06 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text09 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li07 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text10 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li08 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text11 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li09 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text12 {
    font-size: 1.8vh;
  }
  .pageslider-signs-next2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-signs-p4 {
    width: 100%;
  }
  .pageslider-signs-video3 {
    width: 100%;
  }
  .pageslider-signs-text13 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-signs-ul3 {
    width: 100%;
    padding-left: 0px;
  }
  .pageslider-signs-li10 {
    font-size: 1.8vh;
    margin-left: 0px;
  }
  .pageslider-signs-text14 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li11 {
    font-size: 1.8vh;
    margin-left: 0px;
  }
  .pageslider-signs-text15 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li12 {
    font-size: 1.8vh;
    margin-left: 0px;
  }
  .pageslider-signs-text16 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li13 {
    font-size: 1.8vh;
    margin-left: 0px;
  }
  .pageslider-signs-text17 {
    font-size: 1.8vh;
  }
  .pageslider-signs-next3 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-signs-p5 {
    width: 100%;
  }
  .pageslider-signs-video4 {
    width: 100%;
  }
  .pageslider-signs-text18 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-signs-ul4 {
    width: 100%;
    padding-left: 0px;
  }
  .pageslider-signs-li14 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text19 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li15 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text20 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li16 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text21 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li17 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text22 {
    font-size: 1.8vh;
  }
  .pageslider-signs-li18 {
    font-size: 1.8vh;
  }
  .pageslider-signs-text23 {
    font-size: 1.8vh;
  }
  .pageslider-signs-next4 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
.pageslider-intro-container_nav {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-replay {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-pause {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-play {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next:hover {
  fill: #777070;
}