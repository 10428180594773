.introduction-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}
.introduction-header {
  width: 100%;
  height: 5vh;
  display: flex;
  padding: 3vh;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.introduction-text {
  color: var(--dl-color-gray-white);
  width: 100%;
  /* padding: 1vh; */
  font-size: 2vh;
  margin: 0;
  align-self: center;
  text-align: center;
  font-family: Inter;
  font-weight: 800;
  text-transform: none;
  text-decoration: none;
}
.introduction-footer {
  width: 100%;
  bottom: 0;
  height: 5vh;
  display: flex;
  position: absolute;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.introduction-text1 {
  font-size: 1vh;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.introduction-text3 {
  color: var(--dl-color-primary-300);
}
.introduction-text5 {
  font-size: 1vh;
  margin-top: 1vh;
}
.trademark {
  font-size: 1vh;
  }
@media(max-width: 1600px) {
  .introduction-text {
    font-size: 2vh;
  }
  .trademark {
    font-size: 1vh;
    }
}
@media(max-width: 1200px) {
  .introduction-text {
    font-size: 3vh;
  }
  .introduction-text1 {
    text-align: center;
  }
}
@media(max-width: 991px) {
  .introduction-text {
    font-size: 3vh;
  }
  .introduction-text1 {
    text-align: center;
  }
  .introduction-text5 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .introduction-text {  
    font-size: 2vh;
  }
}
@media(max-width: 479px) {
  .introduction-text {
    font-size: 2vh;
  }
  .introduction-footer {
    left: 0px;
    right: 0px;
    width: auto;
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .introduction-text5 {
    margin-top: 1vh;
  }
  .trademark {
    font-size: 1vh;
    }
}
