.modules-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.introduction-header {
  width: 100%;
  height: 5vh;
  display: flex;
  padding: 3vh;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-primary-100);
}
.training-header {
  width: 100%;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: var(--dl-color-primary-100); */
  color: black;
  font-family: Inter;
  font-size: 2vh;
  text-align: center;
  margin-top: 2vh; /* Adjust this value to position the training header correctly */
}
.training-header h2 {
  font-weight: 800;
  margin-bottom: 1vh;
}

.training-header p, .training-header ul {
  font-weight: 400;
}

.introduction-text {
  margin: 0;
  font-size: 2em;
}

.orrs-modules {
  /* margin-top: 7vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 2vh auto;
}
  
.orrs-modules button {
color: var(--dl-color-gray-white);
width: 100%;
padding: 1vh;
font-size: 2vh;
margin: 1vh;
align-self: center;
text-align: center;
font-family: Inter;
font-weight: 800;
text-transform: none;
text-decoration: none;
background-color: var(--dl-color-primary-100);
;
}
  .orrs-modules button:hover {
  background-color: #333;
  color: #fff;
}
.introduction-footer {
  width: 100%;
  bottom: 0;
  height: 5vh;
  display: flex;
  position: absolute;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.trademark {
  font-size: 1vh;
  }
@media(max-width: 1600px) {
    .orrs-modules button {
      font-size: 2vh;
    }
      .introduction-text {
    font-size: 2vh;
  }
  .trademark {
    font-size: 1vh;
    }
  }
  
  @media(max-width: 1200px) {
    .orrs-modules button {
      font-size: 2vh;
    }
      .introduction-text {
    font-size: 3vh;
  }
  .introduction-text1 {
    text-align: center;
  }
  }
  
  @media(max-width: 991px) {
    .orrs-modules button {
      font-size: 2vh;
    }
      .introduction-text {
    font-size: 3vh;
  }
  .introduction-text1 {
    text-align: center;
  }
  .introduction-text5 {
    text-align: center;
  }
  }
  
  @media(max-width: 767px) {
    .orrs-modules button {
      font-size: 2vh;
    }
      .introduction-text {  
    font-size: 2vh;
  }
  }
  
  @media(max-width: 479px) {
    .orrs-modules button {
      font-size: 2vh;
    }
      .introduction-text {
    font-size: 2vh;
  }
  .introduction-footer {
    left: 0px;
    right: 0px;
    width: auto;
    margin-top: var(--dl-space-space-unit);
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .trademark {
    font-size: 1vh;
    }
  }