.pageslider-conc-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
  flex-direction: column;
  padding-bottom: 5vh;
  justify-content: flex-start;
}
.pageslider-conc-p1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video {
  width: 35%;
  height: auto;
  margin-top: 1vh;
}
.pageslider-conc-summarybutton {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: none;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text {
  color: #ff631e;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-conc-li {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-conc-text1 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li1 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text2 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next:hover {
  fill: #777070;
}
.pageslider-conc-p2 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video1 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton1 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton1:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary1 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: none;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text3 {
  color: #ff631e;
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul1 {
  width: auto;
  height: auto;
}
.pageslider-conc-li2 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text4 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li3 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text5 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li4 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text6 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li5 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container8 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text7 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next1 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  display: none;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next1:hover {
  fill: #777070;
}
.pageslider-conc-button {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-100);
}

@media(max-width: 1600px) {
  .pageslider-conc-video {
    width: 45%;
  }
  .pageslider-conc-video1 {
    width: 45%;
  }
  .pageslider-conc-text3 {
    color: rgb(255, 99, 30);
  }
}
@media(max-width: 1200px) {
  .pageslider-conc-summary {
    width: 80%;
  }
  .pageslider-conc-li {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-container2 {
    justify-content: flex-start;
  }
  .pageslider-conc-li1 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-container3 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary1 {
    width: 80%;
  }
  .pageslider-conc-text3 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-li2 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container5 {
    justify-content: flex-start;
  }
  .pageslider-conc-li3 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container6 {
    justify-content: flex-start;
  }
  .pageslider-conc-li4 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container7 {
    justify-content: flex-start;
  }
  .pageslider-conc-li5 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container8 {
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pageslider-conc-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container1 {
    flex-direction: column;
  }
  .pageslider-conc-video {
    width: 50%;
  }
  .pageslider-conc-p2 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container4 {
    flex-direction: column;
  }
  .pageslider-conc-video1 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .pageslider-conc-video {
    width: 70%;
  }
  .pageslider-conc-text {
    width: 100%;
  }
  .pageslider-conc-video1 {
    width: 70%;
  }
  .pageslider-conc-text3 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pageslider-conc-container {
    width: 100%;
    height: 100%;
  }
  .pageslider-conc-p1 {
    width: 100%;
  }
  .pageslider-conc-video {
    width: 100%;
  }
  .pageslider-conc-summary {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-conc-li {
    font-size: 1.8vh;
  }
  .pageslider-conc-text1 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li1 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text2 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p2 {
    width: 100%;
  }
  .pageslider-conc-video1 {
    width: 100%;
  }
  .pageslider-conc-summary1 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text3 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul1 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: decimal;
    list-style-position: outside;
  }
  .pageslider-conc-li2 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text4 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li3 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text5 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li4 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text6 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li5 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text7 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
.pageslider-intro-container_nav {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 0.2vh;
  margin-bottom: 0.2vh;

}
.pageslider-intro-replay {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-pause {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-play {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  transition: 0.3s;
}
.pageslider-intro-next:hover {
  fill: #777070;
}

.pageslider-conc-summarybutton {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: none;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text {
  color: #ff631e;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-conc-li {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-conc-text01 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li01 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text02 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next:hover {
  fill: #777070;
}
.pageslider-conc-p2 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video1 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton1 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton1:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary1 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: none;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text03 {
  color: #ff631e;
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul1 {
  width: auto;
  height: auto;
}
.pageslider-conc-li02 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text04 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li03 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text05 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li04 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text06 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li05 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-conc-text07 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next1 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  display: none;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next1:hover {
  fill: #777070;
}
.pageslider-conc-button {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  background-color: var(--dl-color-primary-100);
}
.pageslider-conc-p3 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video2 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton2 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton2:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary2 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text08 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul2 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-conc-li06 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text09 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li07 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text10 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li08 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text11 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li09 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text12 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-next2 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next2:hover {
  fill: #777070;
}
.pageslider-conc-p4 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video3 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton3 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton3:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary3 {
  width: 60%;
  height: auto;
  margin: 0.2vh;
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text13 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul3 {
  width: auto;
  height: auto;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.pageslider-conc-li10 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text14 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li11 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text15 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li12 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text16 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li13 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text17 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-next3 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next3:hover {
  fill: #777070;
}
.pageslider-conc-p5 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video4 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton4 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton4:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary4 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text18 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul4 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-conc-li14 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text19 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li15 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text20 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li16 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text21 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li17 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text22 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-next4 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next4:hover {
  fill: #777070;
}
.pageslider-conc-p6 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video5 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton5 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton5:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary5 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text23 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul5 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-conc-li18 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text24 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
  width: 100%;
}
.pageslider-conc-li19 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text25 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next5 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next5:hover {
  fill: #777070;
}
.pageslider-conc-p7 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container27 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video6 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton6 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton6:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary6 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text26 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul6 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-conc-li20 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container28 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text27 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li21 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text28 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li22 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text29 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li23 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text30 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next6 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next6:hover {
  fill: #777070;
}
.pageslider-conc-p8 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video7 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton7 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton7:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary7 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text31 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul7 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-conc-li24 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container33 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text32 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li25 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container34 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text33 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next7 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next7:hover {
  fill: #777070;
}
.pageslider-conc-p9 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-conc-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-conc-video8 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-conc-summarybutton8 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-conc-summarybutton8:hover {
  background-color: #e2e2e2;
}
.pageslider-conc-summary8 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-conc-text34 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-conc-ul8 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-conc-li26 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container36 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text35 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-li27 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-conc-container37 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-conc-text36 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-conc-next8 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-conc-next8:hover {
  fill: #777070;
}

@media(max-width: 1600px) {
  .pageslider-conc-video {
    width: 35%;
  }
  .pageslider-conc-video1 {
    width: 45%;
  }
  .pageslider-conc-text03 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-video2 {
    width: 45%;
  }
  .pageslider-conc-text08 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-video3 {
    width: 45%;
  }
  .pageslider-conc-text13 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-video4 {
    width: 45%;
  }
  .pageslider-conc-text18 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-video5 {
    width: 45%;
  }
  .pageslider-conc-text23 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-video6 {
    width: 45%;
  }
  .pageslider-conc-text26 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-video7 {
    width: 45%;
  }
  .pageslider-conc-text31 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-video8 {
    width: 45%;
  }
  .pageslider-conc-text34 {
    color: rgb(255, 99, 30);
  }
}
@media(max-width: 1200px) {
  .pageslider-conc-summary {
    width: 80%;
  }
  .pageslider-conc-li {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-container02 {
    justify-content: flex-start;
  }
  .pageslider-conc-li01 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-container03 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary1 {
    width: 80%;
  }
  .pageslider-conc-text03 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-li02 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container05 {
    justify-content: flex-start;
  }
  .pageslider-conc-li03 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container06 {
    justify-content: flex-start;
  }
  .pageslider-conc-li04 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container07 {
    justify-content: flex-start;
  }
  .pageslider-conc-li05 {
    margin-top: var(--dl-space-space-halfunit);
    list-style-type: decimal;
    list-style-image: none;
    list-style-position: outside;
  }
  .pageslider-conc-container08 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary2 {
    width: 80%;
  }
  .pageslider-conc-text08 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-container10 {
    justify-content: flex-start;
  }
  .pageslider-conc-container11 {
    justify-content: flex-start;
  }
  .pageslider-conc-container12 {
    justify-content: flex-start;
  }
  .pageslider-conc-container13 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary3 {
    width: 80%;
  }
  .pageslider-conc-text13 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-container15 {
    justify-content: flex-start;
  }
  .pageslider-conc-container16 {
    justify-content: flex-start;
  }
  .pageslider-conc-container17 {
    justify-content: flex-start;
  }
  .pageslider-conc-container18 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary4 {
    width: 80%;
  }
  .pageslider-conc-text18 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-container20 {
    justify-content: flex-start;
  }
  .pageslider-conc-container21 {
    justify-content: flex-start;
  }
  .pageslider-conc-container22 {
    justify-content: flex-start;
  }
  .pageslider-conc-container23 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary5 {
    width: 80%;
  }
  .pageslider-conc-text23 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-container25 {
    justify-content: flex-start;
  }
  .pageslider-conc-container26 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary6 {
    width: 80%;
  }
  .pageslider-conc-text26 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-container28 {
    justify-content: flex-start;
  }
  .pageslider-conc-container29 {
    justify-content: flex-start;
  }
  .pageslider-conc-container30 {
    justify-content: flex-start;
  }
  .pageslider-conc-container31 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary7 {
    width: 80%;
  }
  .pageslider-conc-text31 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-container33 {
    justify-content: flex-start;
  }
  .pageslider-conc-container34 {
    justify-content: flex-start;
  }
  .pageslider-conc-summary8 {
    width: 80%;
  }
  .pageslider-conc-text34 {
    color: rgb(255, 99, 30);
  }
  .pageslider-conc-container36 {
    justify-content: flex-start;
  }
  .pageslider-conc-container37 {
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pageslider-conc-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container01 {
    flex-direction: column;
  }
  .pageslider-conc-video {
    width: 50%;
  }
  .pageslider-conc-p2 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container04 {
    flex-direction: column;
  }
  .pageslider-conc-video1 {
    width: 50%;
  }
  .pageslider-conc-p3 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container09 {
    flex-direction: column;
  }
  .pageslider-conc-video2 {
    width: 50%;
  }
  .pageslider-conc-p4 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container14 {
    flex-direction: column;
  }
  .pageslider-conc-video3 {
    width: 50%;
  }
  .pageslider-conc-p5 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container19 {
    flex-direction: column;
  }
  .pageslider-conc-video4 {
    width: 50%;
  }
  .pageslider-conc-p6 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container24 {
    flex-direction: column;
  }
  .pageslider-conc-video5 {
    width: 50%;
  }
  .pageslider-conc-p7 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container27 {
    flex-direction: column;
  }
  .pageslider-conc-video6 {
    width: 50%;
  }
  .pageslider-conc-p8 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container32 {
    flex-direction: column;
  }
  .pageslider-conc-video7 {
    width: 50%;
  }
  .pageslider-conc-p9 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-conc-container35 {
    flex-direction: column;
  }
  .pageslider-conc-video8 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .pageslider-conc-video {
    width: 70%;
  }
  .pageslider-conc-text {
    width: 100%;
  }
  .pageslider-conc-video1 {
    width: 70%;
  }
  .pageslider-conc-text03 {
    width: 100%;
  }
  .pageslider-conc-video2 {
    width: 70%;
  }
  .pageslider-conc-text08 {
    width: 100%;
  }
  .pageslider-conc-video3 {
    width: 70%;
  }
  .pageslider-conc-text13 {
    width: 100%;
  }
  .pageslider-conc-video4 {
    width: 70%;
  }
  .pageslider-conc-text18 {
    width: 100%;
  }
  .pageslider-conc-video5 {
    width: 70%;
  }
  .pageslider-conc-text23 {
    width: 100%;
  }
  .pageslider-conc-video6 {
    width: 70%;
  }
  .pageslider-conc-text26 {
    width: 100%;
  }
  .pageslider-conc-video7 {
    width: 70%;
  }
  .pageslider-conc-text31 {
    width: 100%;
  }
  .pageslider-conc-video8 {
    width: 70%;
  }
  .pageslider-conc-text34 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pageslider-conc-container {
    width: 100%;
    height: 100%;
  }
  .pageslider-conc-p1 {
    width: 100%;
  }
  .pageslider-conc-video {
    width: 100%;
  }
  .pageslider-conc-summary {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-conc-li {
    font-size: 1.8vh;
  }
  .pageslider-conc-text01 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li01 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text02 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p2 {
    width: 100%;
  }
  .pageslider-conc-video1 {
    width: 100%;
  }
  .pageslider-conc-summary1 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text03 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul1 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: decimal;
    list-style-position: outside;
  }
  .pageslider-conc-li02 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text04 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li03 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text05 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li04 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text06 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li05 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text07 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p3 {
    width: 100%;
  }
  .pageslider-conc-video2 {
    width: 100%;
  }
  .pageslider-conc-summary2 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text08 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul2 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-conc-li06 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text09 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-li07 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text10 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-li08 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text11 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-li09 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text12 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-next2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p4 {
    width: 100%;
  }
  .pageslider-conc-video3 {
    width: 100%;
  }
  .pageslider-conc-summary3 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text13 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul3 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-conc-li10 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text14 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-li11 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text15 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li12 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text16 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-li13 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text17 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-next3 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p5 {
    width: 100%;
  }
  .pageslider-conc-video4 {
    width: 100%;
  }
  .pageslider-conc-summary4 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text18 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul4 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-conc-li14 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text19 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li15 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text20 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li16 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text21 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li17 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text22 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next4 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p6 {
    width: 100%;
  }
  .pageslider-conc-video5 {
    width: 100%;
  }
  .pageslider-conc-summary5 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text23 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul5 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-conc-li18 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text24 {
    font-size: 1.8vh;
    width: 100%;
  }
  .pageslider-conc-li19 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text25 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next5 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p7 {
    width: 100%;
  }
  .pageslider-conc-video6 {
    width: 100%;
  }
  .pageslider-conc-summary6 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text26 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul6 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-conc-li20 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text27 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li21 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text28 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li22 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text29 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li23 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text30 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next6 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p8 {
    width: 100%;
  }
  .pageslider-conc-video7 {
    width: 100%;
  }
  .pageslider-conc-summary7 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text31 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul7 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-conc-li24 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text32 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li25 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text33 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next7 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-p9 {
    width: 100%;
  }
  .pageslider-conc-video8 {
    width: 100%;
  }
  .pageslider-conc-summary8 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-conc-text34 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-conc-ul8 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-conc-li26 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text35 {
    font-size: 1.8vh;
  }
  .pageslider-conc-li27 {
    font-size: 1.8vh;
  }
  .pageslider-conc-text36 {
    font-size: 1.8vh;
  }
  .pageslider-conc-next8 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
