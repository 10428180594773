.pageslider-work-narcan-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
  flex-direction: column;
  padding-bottom: 5vh;
  justify-content: flex-start;
}
.pageslider-work-narcan-p1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-work-narcan-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-work-narcan-video {
  width: 35%;
  height: auto;
  margin-top: 1vh;
}
.pageslider-work-narcan-summarybutton {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-work-narcan-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-work-narcan-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-work-narcan-text {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-work-narcan-ul {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-work-narcan-li {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-work-narcan-text01 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-li1 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-work-narcan-text02 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-li2 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-work-narcan-text03 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-work-narcan-next:hover {
  fill: #777070;
}
.pageslider-work-narcan-p2 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-work-narcan-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-work-narcan-video1 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-work-narcan-summarybutton1 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-work-narcan-summarybutton1:hover {
  background-color: #e2e2e2;
}
.pageslider-work-narcan-summary1 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-work-narcan-text04 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-work-narcan-ul1 {
  width: auto;
  height: auto;
  list-style-type: decimal;
}
.pageslider-work-narcan-li3 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-work-narcan-text05 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-li4 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-work-narcan-text06 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-li5 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-work-narcan-text07 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-li6 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-work-narcan-text08 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-next1 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-work-narcan-next1:hover {
  fill: #777070;
}
.pageslider-work-narcan-p3 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-work-narcan-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-work-narcan-video2 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-work-narcan-summarybutton2 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-work-narcan-summarybutton2:hover {
  background-color: #e2e2e2;
}
.pageslider-work-narcan-summary2 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-work-narcan-text09 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-work-narcan-ul2 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-work-narcan-li7 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-work-narcan-text10 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-li8 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-work-narcan-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-work-narcan-text11 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-work-narcan-next2 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-work-narcan-next2:hover {
  fill: #777070;
}

@media(max-width: 1600px) {
  .pageslider-work-narcan-video {
    width: 35%;
  }
  .pageslider-work-narcan-video1 {
    width: 45%;
  }
  .pageslider-work-narcan-video2 {
    width: 45%;
  }
  .pageslider-work-narcan-text09 {
    color: rgb(255, 99, 30);
  }
}
@media(max-width: 1200px) {
  .pageslider-work-narcan-summary {
    width: 80%;
  }
  .pageslider-work-narcan-li {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-container02 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-li1 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-container03 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-li2 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-container04 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-summary1 {
    width: 80%;
  }
  .pageslider-work-narcan-li3 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-container06 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-li4 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-container07 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-li5 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-container08 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-li6 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-container09 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-summary2 {
    width: 80%;
  }
  .pageslider-work-narcan-text09 {
    color: rgb(255, 99, 30);
  }
  .pageslider-work-narcan-container11 {
    justify-content: flex-start;
  }
  .pageslider-work-narcan-container12 {
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pageslider-work-narcan-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-work-narcan-container01 {
    flex-direction: column;
  }
  .pageslider-work-narcan-video {
    width: 50%;
  }
  .pageslider-work-narcan-p2 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-work-narcan-container05 {
    flex-direction: column;
  }
  .pageslider-work-narcan-video1 {
    width: 50%;
  }
  .pageslider-work-narcan-p3 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-work-narcan-container10 {
    flex-direction: column;
  }
  .pageslider-work-narcan-video2 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .pageslider-work-narcan-video {
    width: 70%;
  }
  .pageslider-work-narcan-text {
    width: 100%;
  }
  .pageslider-work-narcan-video1 {
    width: 70%;
  }
  .pageslider-work-narcan-text04 {
    width: 100%;
  }
  .pageslider-work-narcan-video2 {
    width: 70%;
  }
  .pageslider-work-narcan-text09 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pageslider-work-narcan-container {
    width: 100%;
    height: 100%;
  }
  .pageslider-work-narcan-p1 {
    width: 100%;
  }
  .pageslider-work-narcan-video {
    width: 100%;
  }
  .pageslider-work-narcan-summary {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-work-narcan-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-work-narcan-li {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text01 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-li1 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text02 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-li2 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text03 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-p2 {
    width: 100%;
  }
  .pageslider-work-narcan-video1 {
    width: 100%;
  }
  .pageslider-work-narcan-summary1 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-text04 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-work-narcan-ul1 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-work-narcan-li3 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text05 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-li4 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text06 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-li5 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text07 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-li6 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text08 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-next1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-p3 {
    width: 100%;
  }
  .pageslider-work-narcan-video2 {
    width: 100%;
  }
  .pageslider-work-narcan-summary2 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-work-narcan-text09 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-work-narcan-ul2 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-work-narcan-li7 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text10 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-li8 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text11 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-text12 {
    font-size: 1.8vh;
  }
  .pageslider-work-narcan-next2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}

.pageslider-intro-container_nav {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-replay {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-pause {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-play {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next:hover {
  fill: #777070;
}