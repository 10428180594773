.pageslider-cpr-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0.5vh;
  padding-left: 0vh;
  padding-right: 0vh;
  flex-direction: column;
  padding-bottom: 5vh;
  justify-content: flex-start;
}
.pageslider-cpr-p1 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video {
  width: 35%;
  height: auto;
  margin-top: 1vh;
}
.pageslider-cpr-summarybutton {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-cpr-li {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-cpr-text01 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li01 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-cpr-text02 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next:hover {
  fill: #777070;
}
.pageslider-cpr-p2 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video1 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton1 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton1:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary1 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text05 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul1 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-cpr-li02 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text06 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li03 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-cpr-text07 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next1 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next1:hover {
  fill: #777070;
}
.pageslider-cpr-p3 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video2 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton2 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton2:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary2 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text08 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul2 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-cpr-li04 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text09 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li05 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-cpr-text10 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next2 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next2:hover {
  fill: #777070;
}
.pageslider-cpr-p4 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video3 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton3 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton3:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary3 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text11 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul3 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-cpr-li06 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text12 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next3 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next3:hover {
  fill: #777070;
}
.pageslider-cpr-p5 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video4 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton4 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton4:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary4 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text13 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul4 {
  width: auto;
  height: auto;
  list-style-type: decimal;
}
.pageslider-cpr-li07 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text14 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li08 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text15 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li09 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text16 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li10 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text17 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next4 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next4:hover {
  fill: #777070;
}
.pageslider-cpr-p6 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video5 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton5 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton5:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary5 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text18 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul5 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-cpr-li11 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text19 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li12 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text20 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li13 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text21 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li14 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text22 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li15 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text23 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next5 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next5:hover {
  fill: #777070;
}
.pageslider-cpr-p7 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container23 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video6 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton6 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton6:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary6 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0.5vh;
}
.pageslider-cpr-text24 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul6 {
  width: auto;
  height: auto;
  list-style-type: circle;
  margin-top: 0;
  margin-bottom: 0;
}
.pageslider-cpr-li16 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text25 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li17 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text26 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li18 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text27 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li19 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container27 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text28 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li20 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container28 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text29 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li21 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: decimal;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container29 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text30 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next6 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next6:hover {
  fill: #777070;
}
.pageslider-cpr-p8 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container30 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video7 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton7 {
  display: none;
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton7:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary7 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text31 {
  color: rgb(255, 99, 30);
  width: auto;
  height: auto;
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul7 {
  width: auto;
  height: auto;
  padding-left: 0px;
}
.pageslider-cpr-li22 {
  width: auto;
  font-size: 2.3vh;
  text-align: center;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container31 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-cpr-text32 {
  font-size: 2.3vh;
  margin-left: 0px;
}
.pageslider-cpr-li23 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container32 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-cpr-text33 {
  font-size: 2.3vh;
  margin-left: 0px;
}
.pageslider-cpr-li24 {
  width: auto;
  font-size: 2.3vh;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container33 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageslider-cpr-text36 {
  font-size: 2.3vh;
  margin-left: 0px;
}
.pageslider-cpr-next7 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next7:hover {
  fill: #777070;
}
.pageslider-cpr-p9 {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}
.pageslider-cpr-container34 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pageslider-cpr-video8 {
  width: 30%;
  height: auto;
  margin-top: var(--dl-space-space-unit);
}
.pageslider-cpr-summarybutton8 {
  font-size: 1.5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}
.pageslider-cpr-summarybutton8:hover {
  background-color: #e2e2e2;
}
.pageslider-cpr-summary8 {
  width: 60%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  z-index: 100;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pageslider-cpr-text39 {
  color: rgb(255, 99, 30);
  font-size: 2.5vh;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}
.pageslider-cpr-ul8 {
  width: auto;
  height: auto;
  list-style-type: circle;
}
.pageslider-cpr-li25 {
  width: auto;
  position: relative;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container35 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pageslider-cpr-text40 {
  width: auto;
  height: auto;
  font-size: 2.3vh;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  padding-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-li26 {
  width: auto;
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  list-style-type: circle;
  list-style-image: none;
  list-style-position: outside;
}
.pageslider-cpr-container36 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.pageslider-cpr-text41 {
  font-size: 2.3vh;
  margin-left: var(--dl-space-space-halfunit);
}
.pageslider-cpr-next8 {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-cpr-next8:hover {
  fill: #777070;
}

@media(max-width: 1600px) {
  .pageslider-cpr-video {
    width: 35%;
  }
  .pageslider-cpr-text {
    color: rgb(255, 99, 30);
  }
  .pageslider-cpr-video1 {
    width: 35%;
  }
  .pageslider-cpr-video2 {
    width: 35%;
  }
  .pageslider-cpr-video3 {
    width: 35%;
  }
  .pageslider-cpr-video4 {
    width: 35%;
  }
  .pageslider-cpr-video5 {
    width: 35%;
  }
  .pageslider-cpr-video6 {
    width: 35%;
  }
  .pageslider-cpr-video7 {
    width: 35%;
  }
  .pageslider-cpr-text31 {
    color: rgb(255, 99, 30);
  }
  .pageslider-cpr-video8 {
    width: 35%;
  }
}
@media(max-width: 1200px) {
  .pageslider-cpr-summary {
    width: 80%;
  }
  .pageslider-cpr-text {
    color: rgb(255, 99, 30);
  }
  .pageslider-cpr-container02 {
    justify-content: flex-start;
  }
  .pageslider-cpr-container03 {
    justify-content: flex-start;
  }
  .pageslider-cpr-summary1 {
    width: 80%;
  }
  .pageslider-cpr-li02 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container05 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li03 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container06 {
    justify-content: flex-start;
  }
  .pageslider-cpr-summary2 {
    width: 80%;
  }
  .pageslider-cpr-li04 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container08 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li05 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container09 {
    justify-content: flex-start;
  }
  .pageslider-cpr-summary3 {
    width: 80%;
  }
  .pageslider-cpr-li06 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container11 {
    justify-content: flex-start;
  }
  .pageslider-cpr-summary4 {
    width: 80%;
  }
  .pageslider-cpr-li07 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container13 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li08 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container14 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li09 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container15 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li10 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container16 {
    justify-content: flex-start;
  }
  .pageslider-cpr-summary5 {
    width: 80%;
    margin-bottom: 0;
    margin-top: 0;
  }
  .pageslider-cpr-li11 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container18 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li12 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container19 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li13 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container20 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li14 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container21 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li15 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container22 {
    justify-content: flex-start;
  }
  .pageslider-cpr-summary6 {
    width: 80%;
  }
  .pageslider-cpr-li16 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container24 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li17 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container25 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li18 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container26 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li19 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container27 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li20 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container28 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li21 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container29 {
    justify-content: flex-start;
  }
  .pageslider-cpr-summary7 {
    width: 80%;
  }
  .pageslider-cpr-text31 {
    color: rgb(255, 99, 30);
  }
  .pageslider-cpr-summary8 {
    width: 80%;
  }
  .pageslider-cpr-li25 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container35 {
    justify-content: flex-start;
  }
  .pageslider-cpr-li26 {
    margin-top: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-container36 {
    justify-content: flex-start;
  }
}
@media(max-width: 991px) {
  .pageslider-cpr-p1 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container01 {
    flex-direction: column;
  }
  .pageslider-cpr-video {
    width: 50%;
  }
  .pageslider-cpr-p2 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container04 {
    flex-direction: column;
  }
  .pageslider-cpr-video1 {
    width: 50%;
  }
  .pageslider-cpr-p3 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container07 {
    flex-direction: column;
  }
  .pageslider-cpr-video2 {
    width: 50%;
  }
  .pageslider-cpr-p4 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container10 {
    flex-direction: column;
  }
  .pageslider-cpr-video3 {
    width: 50%;
  }
  .pageslider-cpr-p5 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container12 {
    flex-direction: column;
  }
  .pageslider-cpr-video4 {
    width: 50%;
  }
  .pageslider-cpr-p6 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container17 {
    flex-direction: column;
  }
  .pageslider-cpr-video5 {
    width: 50%;
  }
  .pageslider-cpr-p7 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container23 {
    flex-direction: column;
  }
  .pageslider-cpr-video6 {
    width: 50%;
  }
  .pageslider-cpr-p8 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container30 {
    flex-direction: column;
  }
  .pageslider-cpr-video7 {
    width: 50%;
  }
  .pageslider-cpr-p9 {
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .pageslider-cpr-container34 {
    flex-direction: column;
  }
  .pageslider-cpr-video8 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .pageslider-cpr-video {
    width: 60%;
  }
  .pageslider-cpr-text {
    width: 100%;
  }
  .pageslider-cpr-video1 {
    width: 70%;
  }
  .pageslider-cpr-text05 {
    width: 100%;
  }
  .pageslider-cpr-video2 {
    width: 70%;
  }
  .pageslider-cpr-text08 {
    width: 100%;
  }
  .pageslider-cpr-video3 {
    width: 70%;
  }
  .pageslider-cpr-text11 {
    width: 100%;
  }
  .pageslider-cpr-video4 {
    width: 70%;
  }
  .pageslider-cpr-text13 {
    width: 100%;
  }
  .pageslider-cpr-video5 {
    width: 70%;
  }
  .pageslider-cpr-text18 {
    width: 100%;
  }
  .pageslider-cpr-video6 {
    width: 70%;
  }
  .pageslider-cpr-text24 {
    width: 100%;
  }
  .pageslider-cpr-video7 {
    width: 70%;
  }
  .pageslider-cpr-text31 {
    width: 100%;
  }
  .pageslider-cpr-video8 {
    width: 70%;
  }
  .pageslider-cpr-text39 {
    width: 100%;
  }
  .pageslider-cpr-summary6 span{
    font-size: 1.8vh;
  }
}
@media(max-width: 479px) {
  .pageslider-cpr-container {
    width: 100%;
    height: 100%;
  }
  .pageslider-cpr-p1 {
    width: 100%;
  }
  .pageslider-cpr-video {
    width: 100%;
  }
  .pageslider-cpr-summary {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-cpr-li {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text01 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li01 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text02 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text03 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p2 {
    width: 100%;
  }
  .pageslider-cpr-video1 {
    width: 100%;
  }
  .pageslider-cpr-summary1 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text05 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul1 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-cpr-li02 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text06 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li03 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text07 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p3 {
    width: 100%;
  }
  .pageslider-cpr-video2 {
    width: 100%;
  }
  .pageslider-cpr-summary2 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text08 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul2 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-cpr-li04 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text09 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li05 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text10 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p4 {
    width: 100%;
  }
  .pageslider-cpr-video3 {
    width: 100%;
  }
  .pageslider-cpr-summary3 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text11 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul3 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-cpr-li06 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text12 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next3 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p5 {
    width: 100%;
  }
  .pageslider-cpr-video4 {
    width: 100%;
  }
  .pageslider-cpr-summary4 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text13 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul4 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-cpr-li07 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text14 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li08 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text15 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li09 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text16 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li10 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text17 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next4 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p6 {
    width: 100%;
  }
  .pageslider-cpr-video5 {
    width: 100%;
  }
  .pageslider-cpr-summary5 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text18 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul5 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-cpr-li11 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text19 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li12 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text20 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li13 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text21 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li14 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text22 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li15 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text23 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next5 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p7 {
    width: 100%;
  }
  .pageslider-cpr-video6 {
    width: 100%;
  }
  .pageslider-cpr-summary6 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
    margin-bottom: 0;
  }
  .pageslider-cpr-text24 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul6 {
    width: auto;
    margin-top: 0;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-cpr-li16 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text25 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li17 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text26 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li18 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text27 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li19 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text28 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li20 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text29 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li21 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text30 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next6 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p8 {
    width: 100%;
  }
  .pageslider-cpr-video7 {
    width: 100%;
  }
  .pageslider-cpr-summary7 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text31 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul7 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .pageslider-cpr-li22 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text32 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li23 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text33 {
    font-size: 1.8vh;
    text-align: center;
  }
  .pageslider-cpr-text34 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li24 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text36 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text37 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next7 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-p9 {
    width: 100%;
  }
  .pageslider-cpr-video8 {
    width: 100%;
  }
  .pageslider-cpr-summary8 {
    width: auto;
    margin: var(--dl-space-space-halfunit);
  }
  .pageslider-cpr-text39 {
    color: rgb(255, 99, 30);
    width: auto;
    font-size: 2vh;
    font-style: normal;
    font-weight: 700;
  }
  .pageslider-cpr-ul8 {
    width: auto;
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
    list-style-type: georgian;
    list-style-position: outside;
  }
  .pageslider-cpr-li25 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text40 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-li26 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-text41 {
    font-size: 1.8vh;
  }
  .pageslider-cpr-next8 {
    margin-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
.pageslider-intro-container_nav {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.pageslider-intro-replay {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-pause {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-play {
  width: 5vh;
  height: 5vh;
  margin-right: var(--dl-space-space-halfunit);
}
.pageslider-intro-next {
  fill: #392929;
  width: 5vh;
  height: 5vh;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-unit);
}
.pageslider-intro-next:hover {
  fill: #777070;
}